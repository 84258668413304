<template>
  <b-card>
    <b-form @submit.prevent="save">
      <b-row>
        <b-col cols="12" class="mb-2"><h3>Update Kartu Rencana Studi</h3></b-col>

        <b-col cols="12">
          <b-form-group label="NIM" label-for="h-nim" label-cols-md="2">
            <b-form-input
              id="h-nim"
              placeholder="Nomor Induk Mahasiswa"
              disabled
              v-model="model.nim"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Nama Mahasiswa"
            label-for="h-name"
            label-cols-md="2"
          >
            <b-form-input
              id="h-name"
              placeholder="Nama Mahasiswa"
              disabled
              v-model="model.name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Status" label-for="h-status" label-cols-md="2">
            <b-form-select v-model="model.status" :options="options" />
          </b-form-group>
        </b-col>

        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="$router.go(-1)"
          >
            Kembali
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
          >
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        api: "student-grades",
        redirect: "study-plan",
      },
      model: {
        nim: null,
        name: null,
        status: "unpaid",
      },
      options: [
        { value: "unpaid", text: "Belum Bayar" },
        { value: "paid", text: "Sudah Bayar" },
      ],
    };
  },
  created() {
    const _ = this;
    _.loadData();
  },
  methods: {
    loadData() {
      const _ = this;
      _.model = _.$store.getters["studyPlan/GET_USER"];
    },
    save() {
      const _ = this;

      _.$axios
        .put(`${_.config.api}/${_.$route.params.id}`, { status: _.model.status })
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              text: "Data Berhasil di Ubah",
            },
          });
        });
    }
  },
};
</script>

<style></style>
